@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Minecraft';
  src: url('./assets/fonts/Minecraft.ttf') format('truetype'),
       url('./assets/fonts/Minecraft.woff') format('woff'),
       url('./assets/fonts/Minecraft.woff2') format('woff2'),

  ;
}

@font-face {
  font-family: 'Andale';
  src: url('./assets/fonts/andale-mono.ttf') format('truetype'),
       url('./assets/fonts/andale-mono.woff') format('woff'),
       url('./assets/fonts/andale-mono.woff2') format('woff2'),

  ;
}

html {
  ::-webkit-scrollbar {
    width: 7.5px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background: transparent; 
  }  
  ::-webkit-scrollbar-thumb {
    cursor: pointer;
    border-radius: 10px;
    background: #A1A1A150; 
  }  
  ::-webkit-scrollbar-thumb:hover {
    background: #A1A1A1f0; 
  }
  ::-webkit-scrollbar-thumb:active {
    background: #A1A1A1f0; 
  }
  overflow: hidden;
  background-color: black;
  height: -webkit-fill-available;
}


body {
  height: -webkit-fill-available;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: url('./assets/cursor-default.png'), auto !important;
}

a, .pointer {
  cursor: url('./assets/cursor-pointer.png'), pointer !important;
}

#root {
  height: 100vh;
  width: 100vw;
  position: relative;
  height: -webkit-fill-available;
}  

#app {
  height: 100vh;
  width: 100vw;
  position: relative;
  height: -webkit-fill-available;
}  

.pixel {
  image-rendering: pixelated;
}

.window {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    height: fit-content;
}

.window-body {
  height: fit-content;
}

.noise {
  mix-blend-mode: darken;
  opacity: 0.2;
}

.neon {
  filter: drop-shadow(0px 0px 8px white) drop-shadow(0px 0px 14px #1BD386);
}

.car {
  transform: translateX(125%);
}

div[id^="menu_"]:hover, div[id*=" menu_"]:hover {
  scale: 1.3;
  margin-bottom: 3rem;
}

body:has(div[id^="menu_"]:nth-child(1):hover)  div[id^="menu_"]:nth-child(2) {
  scale: 1.2;
  margin-bottom: 2rem;
}
body:has(div[id^="menu_"]:nth-child(1):hover)  div[id^="menu_"]:nth-child(3) {
  scale: 1.1;
  margin-bottom: 1rem;
}

body:has(div[id^="menu_"]:nth-child(2):hover)  div[id^="menu_"]:nth-child(1) {
  scale: 1.2;
  margin-bottom: 2rem;
}
body:has(div[id^="menu_"]:nth-child(2):hover)  div[id^="menu_"]:nth-child(3) {
  scale: 1.2;
  margin-bottom: 2rem;
}
body:has(div[id^="menu_"]:nth-child(2):hover)  div[id^="menu_"]:nth-child(4) {
  scale: 1.1;
  margin-bottom: 1rem;
}

body:has(div[id^="menu_"]:nth-child(3):hover)  div[id^="menu_"]:nth-child(1) {
  scale: 1.1;
  margin-bottom: 1rem;
}
body:has(div[id^="menu_"]:nth-child(3):hover)  div[id^="menu_"]:nth-child(2) {
  scale: 1.2;
  margin-bottom: 2rem;
}
body:has(div[id^="menu_"]:nth-child(3):hover)  div[id^="menu_"]:nth-child(4) {
  scale: 1.2;
  margin-bottom: 2rem;
}
body:has(div[id^="menu_"]:nth-child(3):hover)  div[id^="menu_"]:nth-child(5) {
  scale: 1.1;
  margin-bottom: 1rem;
}

body:has(div[id^="menu_"]:nth-child(4):hover)  div[id^="menu_"]:nth-child(2) {
  scale: 1.1;
  margin-bottom: 1rem;
}
body:has(div[id^="menu_"]:nth-child(4):hover)  div[id^="menu_"]:nth-child(3) {
  scale: 1.2;
  margin-bottom: 2rem;
}
body:has(div[id^="menu_"]:nth-child(4):hover)  div[id^="menu_"]:nth-child(5) {
  scale: 1.2;
  margin-bottom: 2rem;
}
body:has(div[id^="menu_"]:nth-child(4):hover)  div[id^="menu_"]:nth-child(6) {
  scale: 1.1;
  margin-bottom: 1rem;
}

body:has(div[id^="menu_"]:nth-child(5):hover)  div[id^="menu_"]:nth-child(3) {
  scale: 1.1;
  margin-bottom: 1rem;
}
body:has(div[id^="menu_"]:nth-child(5):hover)  div[id^="menu_"]:nth-child(4) {
  scale: 1.2;
  margin-bottom: 2rem;
}
body:has(div[id^="menu_"]:nth-child(5):hover)  div[id^="menu_"]:nth-child(6) {
  scale: 1.2;
  margin-bottom: 2rem;
}
body:has(div[id^="menu_"]:nth-child(5):hover)  div[id^="menu_"]:nth-child(7) {
  scale: 1.1;
  margin-bottom: 1rem;
}

body:has(div[id^="menu_"]:nth-child(6):hover)  div[id^="menu_"]:nth-child(4) {
  scale: 1.1;
  margin-bottom: 1rem;
}
body:has(div[id^="menu_"]:nth-child(6):hover)  div[id^="menu_"]:nth-child(5) {
  scale: 1.2;
  margin-bottom: 2rem;
}
body:has(div[id^="menu_"]:nth-child(6):hover)  div[id^="menu_"]:nth-child(7) {
  scale: 1.2;
  margin-bottom: 2rem;
}

body:has(div[id^="menu_"]:nth-child(7):hover)  div[id^="menu_"]:nth-child(5) {
  scale: 1.1;
  margin-bottom: 1rem;
}
body:has(div[id^="menu_"]:nth-child(7):hover)  div[id^="menu_"]:nth-child(6) {
  scale: 1.2;
  margin-bottom: 2rem;
}




@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

.tailedDiv::before, .tailedDiv::after {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: conic-gradient(from var(--angle), transparent 50%, #4972e466 52.5%, #ffffff94 62.5%, #ffffffbe 64.5%, transparent 66%);
  top: 0;
  left: 0;
  z-index: 10;
  border-radius: 0.5rem;
  animation: 3s spin linear infinite;
  opacity: 0.6;
}

.tailedDiv::before {
  filter: blur(1.5rem);
  opacity: 0.5;
}

@keyframes spin {
  from { 
    --angle: 0deg; 
  }
  to { 
    --angle: 360deg; 
  }
}

.genii {
  font-family: 'BlinkMacSystemFont';
}

.genii-glow {
  background-image: radial-gradient(circle at center, rgba(73, 114, 228, 0.4), rgba(73,114,228, .07) 30%, transparent 60%);
}

